<template>
  <div class="borrowingDetail">
    <div class="borrowingDetail-box">
      <div class="borrowingDetail-t">
        <van-swipe class="my-swipe" indicator-color="white">
          <div
            class="oSwiper"
            v-for="(item, index) in oData.imgArr"
            :key="index"
          >
            <van-swipe-item>
              <div class="img"><img :src="item" alt="" /></div>
            </van-swipe-item>
          </div>
        </van-swipe>
      </div>
      <div class="borrowingDetail-b">
        <div class="name">{{ oData.itemName }}</div>
        <div class="num">
          <div class="num-bl">
            可借<span>{{ oData.itemNum }}件</span>
          </div>
          <div class="num-br">已借{{ oData.borrowedTimes }}次</div>
        </div>
        <div class="content">
          <v-h5Mtml :content="oData.content"></v-h5Mtml>
        </div>
        <div class="btn" @click="toBorrow">借物登记</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBorrowItemsByIdUrl, getItemTypeCodeDictUrl } from "./api.js";
import Vue from "vue";
import { Swipe, SwipeItem } from "vant";

Vue.use(Swipe);
Vue.use(SwipeItem);
var moment = require("moment");
export default {
  name: "borrowingDetail",
  data() {
    return {
      id: "",
      oData: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetail();
    // gloabalCount("", 20, 1);
  },
  methods: {
    async getDetail() {
      let params = { id: this.id };
      let res = await this.$axios.get(`${getBorrowItemsByIdUrl}`, { params });
      if (res.code === 200) {
        this.oData = res.data;
        if (this.oData.itemImgUrl) {
          this.oData.imgArr = this.oData.itemImgUrl.split(",");
          this.oData.imgArr.forEach((element) => {
            element = this.$handleImg(680, 624, element);
          });
        }
      }
    },
    toBorrow() {
      this.$router.push({
        name: "borrowingForm",
        query: { id: this.oData.id },
      });
    },
    async toDetail(item) {
      let params = {
        id: item.id,
      };
      this.$router.push({
        name: "borrowingForm",
        query: { id: item.id },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.borrowingDetail {
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .borrowingDetail-box {
    padding: 30px;
    .borrowingDetail-t {
      /deep/ .van-swipe {
        width: 100%;
        .van-swipe__track {
          .oSwiper {
            width: 100%;
            .van-swipe-item {
              width: 100% !important;
            }
          }
        }
        .van-swipe__indicator {
          width: 110px;
          height: 4px;
          background: #ffffff;
          border-radius: 4px;
        }
      }
      .img {
        width: 680px;
        height: 624px;
        overflow: hidden;
        margin: 0 auto;
        border-radius: 10px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
    }
    .borrowingDetail-b {
      .name {
        font-size: 30px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 42px;
        margin: 30px 0 40px;
      }
      .num {
        display: flex;
        align-items: center;
        padding-bottom: 24px;
        border-bottom: 2px solid #eeeeee;
        .num-bl {
          font-size: 24px;
          font-weight: 400;
          color: #e02020;
          line-height: 34px;
          span {
            font-size: 32px;
            font-weight: 700;
          }
        }
        .num-br {
          font-size: 24px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 34px;
          margin-left: 24px;
        }
      }
      .content {
        padding-top: 38px;
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 40px;
      }
      .btn {
        width: 530px;
        height: 70px;
        background: #8a95a6;
        border-radius: 18px;
        position: fixed;
        z-index: 99;
        bottom: 74px;
        left: 0;
        right: 0;
        margin: auto;
        font-size: 28px;
        text-align: center;
        font-weight: 400;
        color: #ffffff;
        line-height: 74px;
      }
    }
  }
}
</style>
<style lang="less">
.borrowingDetail {
}
</style>
